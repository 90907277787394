import { LoginForm } from "../components/Login/LoginForm/LoginForm"
import React from "react"
import SEO from "../components/seo"
import Layout from "../components/shared/Layout/layout"

const LoginPage = () => (
  <Layout fluid={true}>
    <SEO title="Login" />

    <LoginForm />
  </Layout>
)

export default LoginPage
