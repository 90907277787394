import { theme } from "./../../../../components/shared/Theme/Theme"
import styled from "styled-components"
import { css } from "styled-components"

export const FormSectionHeading = styled.h3<{ disabled?: boolean }>`
  font-size: 1.8rem;
  color: ${theme.color.primary};
  font-weight: 500;
  text-align: left;
  margin-top: 2rem;
  margin-bottom: 1rem;
  ${({ disabled = false }) =>
    disabled &&
    css`
      filter: opacity(0.4);
    `}
`
