import {Button, Card, Grid, TextField} from "@material-ui/core"
import {FormSectionHeading} from "../../../components/shared/Registration/FormSectionHeading/FormSectionHeading"
import {Form, Formik} from "formik"
import React from "react"
import styled from "styled-components"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import api from "../../../lib/api/api"
import {useMutation} from "react-query"
import Swal from "sweetalert2"
import {useUserContext} from "@/lib/context/UserContext"

export interface LoginFormProps {
  className?: string
}

const FormCard = styled(Card)`
  padding: 30px;
  max-width: 500px;
`

const FormActions = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
`

const LoginFormInner = (props: LoginFormProps) => {
  const {logOut, logIn, user} = useUserContext()
  const loginMutation = useMutation(async (values: any) => {
    const {data} = await api({
      method: "POST",
      url: `/auth/local`,
      data: {
        identifier: values.email,
        password: values.password,
      },
    })
    return data
  })

  return (
    <div className={props.className}>
      <FormCard>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={async (values: any) => {
            try {
              const {jwt, user} = await loginMutation.mutateAsync(values)
              logIn(user, jwt)
              Swal.fire("Úspešné prihlásenie", "", "success")
            } catch (error) {
              Swal.fire("Neúpešné prihlásenie", "", "error")
            }
          }}
        >
          {(formik) => (
            <Form>
              <FormSectionHeading
                style={{
                  marginTop: 0,
                  marginBottom: "1.8rem",
                  fontSize: "2.2rem",
                }}
              >
                Prihlásenie
              </FormSectionHeading>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={(<>
                      {formik.touched.email && formik.errors.email}
                    </>)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={(<>
                      {formik.touched.password && formik.errors.password}
                    </>)}
                  />
                </Grid>
              </Grid>
              <FormActions>
                <Button type="submit" color="primary" variant="contained" className="upper" endIcon={<LockOpenIcon/>}>
                  Prihlásiť sa
                </Button>
              </FormActions>
            </Form>
          )}
        </Formik>
      </FormCard>
      {user && (
        <Button type="submit" color="secondary" variant="contained" className="upper" onClick={logOut}>
          Odlhásiť sa
        </Button>
      )}
    </div>
  )
}

export const LoginForm = styled(LoginFormInner)`
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
`
